import { Component, EventEmitter, Output } from '@angular/core';

/**
 * ```<sh-zoom-controls-buttons>``` are a group of floating buttons with the common zoom controls
 * zoom in, zoom out and fit
 */
@Component({
	selector: 'sh-zoom-controls-buttons',
	templateUrl: './zoom-controls-buttons.component.html',
	styleUrls: ['./zoom-controls-buttons.component.scss']
})
export class ZoomControlsButtonsComponent {
	/**
	 * Event emitted when zoom-in is clicked
	 */
	@Output()
	public readonly zoomIn = new EventEmitter<void>();

	/**
	 * Event emitted when zoom-out is clicked
	 */
	@Output()
	public readonly zoomOut = new EventEmitter<void>();

	/**
	 * Event emitted when fit in is clicked
	 */
	@Output()
	public readonly fit = new EventEmitter<void>();
}
