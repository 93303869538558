import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
class NgxResizeObserverService {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.count = 0;
    this.elementMap = new Map();
    this.observer = null;
  }
  ngOnDestroy() {
    if (this.observer) {
      this.clearObserver();
    }
  }
  observe(element, callback, boxModel) {
    if (!this.observer) {
      this.observer = new ResizeObserver(resizes => {
        for (const resize of resizes) {
          const cb = this.elementMap.get(resize.target);
          if (cb) {
            this.ngZone.run(() => {
              cb(resize);
            });
          }
        }
      });
    }
    if (boxModel === 'border-box') {
      this.observer.observe(element, {
        box: 'border-box'
      });
    } else {
      this.observer.observe(element);
    }
    this.count += 1;
    this.elementMap.set(element, callback);
  }
  unobserve(element) {
    const cb = this.elementMap.get(element);
    if (cb && this.observer) {
      this.observer.unobserve(element);
      this.elementMap.delete(element);
      this.count -= 1;
      if (this.count === 0) {
        this.clearObserver();
      }
    }
  }
  clearObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.observer = null;
    this.count = 0;
    this.elementMap = new Map();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxResizeObserverService_Factory(t) {
      return new (t || NgxResizeObserverService)(i0.ɵɵinject(i0.NgZone));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxResizeObserverService,
      factory: NgxResizeObserverService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxResizeObserverService, [{
    type: Injectable
  }], () => [{
    type: i0.NgZone
  }], null);
})();
class NgxResizeObserverDirective {
  constructor(elementRef, ngxResizeObserverService) {
    this.elementRef = elementRef;
    this.ngxResizeObserverService = ngxResizeObserverService;
    this.resizeBoxModel = '';
    this.onResize = new EventEmitter();
    this.observing = false;
  }
  ngAfterViewInit() {
    this.observe();
  }
  ngOnChanges(changes) {
    if (this.observing && (changes['resizeBoxModel'] || changes['onResize'])) {
      this.unobserve();
      this.observe();
    }
  }
  ngOnDestroy() {
    this.unobserve();
  }
  observe() {
    if (!this.observing) {
      this.ngxResizeObserverService.observe(this.elementRef.nativeElement, resize => this.onResize.emit(resize), this.resizeBoxModel);
      this.observing = true;
    }
  }
  unobserve() {
    if (this.observing) {
      this.ngxResizeObserverService.unobserve(this.elementRef.nativeElement);
      this.observing = false;
    }
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxResizeObserverDirective_Factory(t) {
      return new (t || NgxResizeObserverDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NgxResizeObserverService));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxResizeObserverDirective,
      selectors: [["", "onResize", ""]],
      inputs: {
        resizeBoxModel: "resizeBoxModel"
      },
      outputs: {
        onResize: "onResize"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxResizeObserverDirective, [{
    type: Directive,
    args: [{
      selector: '[onResize]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: NgxResizeObserverService
  }], {
    resizeBoxModel: [{
      type: Input
    }],
    onResize: [{
      type: Output
    }]
  });
})();
class NgxResizeObserverModule {
  /** @nocollapse */static {
    this.ɵfac = function NgxResizeObserverModule_Factory(t) {
      return new (t || NgxResizeObserverModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxResizeObserverModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NgxResizeObserverService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxResizeObserverModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxResizeObserverDirective],
      exports: [NgxResizeObserverDirective],
      providers: [NgxResizeObserverService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-resize-observer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxResizeObserverDirective, NgxResizeObserverModule, NgxResizeObserverService };
