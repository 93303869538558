// eslint-disable-next-line max-classes-per-file
import { Component, Input, HostBinding, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subject, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { SecnavLayoutStateFacade } from '../+state/secnav-layout-state.facade';
import { SidenavLayoutStateFacade } from '../+state/sidenav-layout-state.facade';

export enum MediaAlias {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg'
}

export type SecnavMode = 'over' | 'push' | 'side';

@Component({
	selector: 'sh-secnav-layout',
	templateUrl: './secondarynav-layout.component.html',
	styleUrls: ['./secondarynav-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecnavLayoutComponent implements OnInit, OnDestroy {
	@Input() public closedMode: SecnavMode = 'over';
	@Input() public isCloseButtonEnabled = true;
	@Input() public closedAlias: MediaAlias[] = [MediaAlias.XS, MediaAlias.SM];
	public opened$!: Observable<boolean>;
	public viewMode: SecnavMode = 'side';

	@Input() public set mode(m: SecnavMode) {
		this.viewMode = m;
		this.openedMode = m;
	}

	private unsubscribe$ = new Subject<void>();
	private openedMode: SecnavMode = 'side';

	constructor(
		private media: MediaObserver,
		private layoutStateFacade: SecnavLayoutStateFacade,
		private sideLayoutStateFascade: SidenavLayoutStateFacade
	) {}

	public ngOnInit(): void {
		this.media
			.asObservable()
			.pipe(
				filter((changes: MediaChange[]) => changes.length > 0),
				map((changes: MediaChange[]) => changes[0]),
				takeUntil(this.unsubscribe$)
			)
			.subscribe((change) => {
				if (this.closedAlias) {
					const isInClosedMode = (<string[]>this.closedAlias).includes(change.mqAlias);
					if (isInClosedMode) {
						this.layoutStateFacade.close();
						this.viewMode = this.closedMode;
					} else {
						this.layoutStateFacade.open();
						this.layoutStateFacade.setIsOpen(true);
						this.viewMode = this.openedMode;
					}
				}
			});
		this.opened$ = this.layoutStateFacade.isOpen$;
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.layoutStateFacade.setIsOpen(false);
	}

	public close(): void {
		this.layoutStateFacade.setIsOpen(false);
		this.layoutStateFacade.close();
		this.sideLayoutStateFascade.close();
	}
}

@Component({
	selector: 'sh-secnav-layout-topbanner',
	template: '<ng-content></ng-content>',
	styleUrls: ['../shared/layout-topbanner.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecnavLayoutTopBannerComponent {}

@Component({
	selector: 'sh-secnav-layout-secnav',
	template: '<ng-content></ng-content>',
	styleUrls: ['./secondarynav-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecnavLayoutsecnavComponent {}

@Component({
	selector: 'sh-secnav-layout-content',
	template: '<ng-content></ng-content>',
	styleUrls: ['./secondarynav-layout-content.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecnavLayoutContentComponent {}

@Component({
	selector: 'sh-secnav-layout-footer',
	templateUrl: '../shared/layout-footer.html',
	styleUrls: ['../shared/layout-footer.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecnavLayoutFooterComponent {
	@Input() public logoPath!: string;
	@HostBinding('class.mat-elevation-z4') public elevationCssClass = true;
}
