export enum DeviceRfStrength {
	Strong = 'strong',
	Moderate = 'moderate',
	Low = 'low',
	VeryLow = 'veryLow',
	None = 'none'
}

export enum DeviceRfDensityMode {
	Standard = 'STANDARD',
	High = 'HIGH'
}

export enum DeviceRfPower {
	Low = 'LOW',
	Medium = 'MEDIUM',
	MediumHigh = 'MEDIUM_HIGH',
	High = 'HIGH'
}
