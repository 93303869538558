export enum ChannelDeviceSpecificEqContour {
	Mxa310 = 'Mxa310',
	Mxa710LowShelf = 'Mxa710LowShelf',
	Mxa901HighPass = 'Mxa901HighPass',
	Mxa902HighPass = 'Mxa902HighPass',
	Mxa910 = 'Mxa910',
	Mxa910HighPass = 'Mxa910HighPass',
	Mxa910MultiBand = 'Mxa910MultiBand',
	Mxa920HighPass = 'Mxa920HighPass',
	Off = 'Off'
}
