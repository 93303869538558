export enum AutomixNoiseDetectionSensitivity {
	Off = 'OFF',
	Low = 'LOW',
	Medium = 'MEDIUM',
	High = 'HIGH'
}

export enum AutomixNoiseRemovalLevel {
	Off = 'OFF',
	Low = 'LOW',
	Medium = 'MEDIUM',
	High = 'HIGH'
}
