import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@jsverse/transloco';

import { ExportFileType } from '../ag-grid-utils';

@Component({
	selector: 'sh-export-button',
	standalone: true,
	imports: [CommonModule, MatMenuModule, MatIconModule, TranslocoModule],
	templateUrl: './export-button.component.html',
	styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent {
	@Input() public buttonDisabled!: boolean | null;
	@Output() public exportFile = new EventEmitter<ExportFileType>();
	public exportFileTypes = Object.values(ExportFileType);

	/**
	 * emit exportFile event
	 * @param type represent type of export files
	 */
	public exportData(type: ExportFileType): void {
		this.exportFile.emit(type);
	}
}
