<div id="sh-carousel-image-container-div" *transloco="let t">
	<img id="sh-carousel-image-img" [src]="imageList[currentImageIndex] | version | async" />
	<div id="sh-carousel-banner-text-div">
		<div id="sh-carousel-welcome-text-div">
			{{ t('cloud.shared.carousel.welcome-to') }}
			<b>{{ t('cloud.shared.carousel.shure-cloud') }} </b>, {{ firstName() }}
		</div>
		<span id="sh-carousel-manage-devices-text-span" *ngIf="isSelfRegisteredUser">{{
			t('cloud.shared.carousel.manage-devices-licenses-and-more')
		}}</span>
	</div>
</div>
