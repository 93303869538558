import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'sh-disabled-action-button',
	templateUrl: './disabled-action-button.component.html',
	styleUrls: ['./disabled-action-button.component.scss'],
	standalone: true,
	imports: [MatIconModule, MatButtonModule]
})
export class DisabledActionButtonComponent implements ICellRendererAngularComp {
	public params!: ICellRendererParams;

	/**
	 *	triggers the renderer in ag cell
	 * @param params -Params for rendering
	 */
	public agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	/**
	 * Return true if you have managed the cell refresh yourself in this method
	 */
	public refresh(params: ICellRendererParams): boolean {
		this.params = params;
		return true;
	}
}
