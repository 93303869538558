import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@jsverse/transloco';

@Component({
	selector: 'sh-paginator',
	templateUrl: 'paginator.component.html',
	styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements AfterViewInit {
	@ViewChild(MatPaginator) public paginator!: MatPaginator;
	@Input() public totalItems!: number;
	@Input() public pageSize = 10;
	@Input() public pageSizeOptions: number[] = [10, 25, 50, 100];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public dataSource!: MatTableDataSource<any>;
	constructor(private translocoService: TranslocoService) {}

	public ngAfterViewInit(): void {
		this.paginator._intl.itemsPerPageLabel = this.translocoService.translate(
			'cloud.shared.paginator.items-per-page'
		);
		this.paginator._intl.firstPageLabel = this.translocoService.translate('cloud.shared.paginator.first-page');
		this.paginator._intl.previousPageLabel = this.translocoService.translate(
			'cloud.shared.paginator.previous-page'
		);
		this.paginator._intl.nextPageLabel = this.translocoService.translate('cloud.shared.paginator.next-page');
		this.paginator._intl.lastPageLabel = this.translocoService.translate('cloud.shared.paginator.last-page');
		this.paginator.length = this.totalItems;
		this.paginator.pageSize = this.pageSize;
		this.dataSource.paginator = this.paginator;
	}
}
