import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SecnavLayoutStateFacade {
	private isOpen = false;
	private _isOpen$ = new BehaviorSubject(this.isOpen);

	public get isOpen$(): Observable<boolean> {
		return this._isOpen$.asObservable();
	}

	public setIsOpen(isOpen: boolean): void {
		this.isOpen = isOpen;
		this._isOpen$.next(isOpen);
	}

	public toggle(): void {
		this.isOpen = !this.isOpen;
		this._isOpen$.next(this.isOpen);
	}

	public open(): void {
		this.isOpen = true;
		this._isOpen$.next(this.isOpen);
	}

	public close(): void {
		this.isOpen = false;
		this._isOpen$.next(this.isOpen);
	}
}
