import { DeviceModel } from './device-model.enum';

export enum DeviceCategory {
	Microphone = 'microphone',
	Speaker = 'speaker',
	Processor = 'processor',
	Hybrid = 'hybrid',
	Control = 'control',
	Amplifier = 'amplifier',
	Unknown = 'unknown'
}
/**
 * `AvailableDevice` represents an device that can be added to routing or coverage.
 */
export interface AvailableDevice {
	model: DeviceModel;
	category: DeviceCategory;
}
