import { Rectangle } from '../shape';

export enum CoverageAreaType {
	Dedicated = 'DEDICATED',
	Dynamic = 'DYNAMIC',
	Temporary = 'TEMPORARY'
}

/**
 * Coverage area rectangle with values in px
 */
export type CoverageAreaRect = Rectangle;

/**
 * Coordinates of two diagonally opposite corners
 * in a rectangular coverage area, with device as origo
 */
export interface RectangularCoverageAreaCoordinates {
	xMax: number;
	yMax: number;
	yMin: number;
	xMin: number;
}
