import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';

import { FormDataDetails, FormTypes, IgniteDialogData } from './dialog-box.model';

@Component({
	standalone: true,
	selector: 'sh-dialog-box-component',
	templateUrl: './dialog-box.component.html',
	styleUrls: ['./dialog-box.component.scss'],
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		FlexLayoutModule,
		MatIconModule,
		ReactiveFormsModule,
		TranslocoModule,
		MatFormFieldModule,
		MatCardModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule
	]
})
export class ShIgniteDialogBoxComponent implements OnInit, OnDestroy {
	public action = '';
	public dialogData: IgniteDialogData | undefined;
	public formGroup: FormGroup = new FormGroup({});
	public formData: FormDataDetails[] | undefined = [];
	public title = '';
	public formType = FormTypes;
	public minDate: Date;
	public domainsList!: string[];
	private readonly destroy$ = new Subject<void>();
	public organizationName!: string;
	public isFormChanged = true;

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<ShIgniteDialogBoxComponent>,
		private oktaIntfService: OktaInterfaceService
	) {
		this.minDate = new Date();
	}

	// HostListener to listen for the "Enter" keypress and trigger form validation
	@HostListener('window:keydown.enter', ['$event'])
	public handleEnterKey(event: KeyboardEvent): void {
		event.preventDefault();
		this.onSubmit();
	}

	public ngOnInit(): void {
		this.oktaIntfService
			.getUserProfile$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((userProfile) => {
				this.organizationName = userProfile.orgName;
			});
		this.formData = (this.dialogData?.formData ?? []).map((formControlDetail) => {
			return { ...formControlDetail, errorLabel: formControlDetail.errorLabel ?? formControlDetail.label };
		});

		this.action = this.dialogData?.action ?? '';

		this.formGroup = this.formBuilder.group({});
		this.formData?.forEach((formControlDetail) => {
			this.formGroup.addControl(formControlDetail.name, new FormControl('', formControlDetail.validators));
			if (formControlDetail.disabled) {
				this.formGroup.controls[formControlDetail.name].disable();
			}
		});

		if (this.formGroup.get('groupName')) {
			this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
				// eslint-disable-next-line dot-notation
				this.isFormChanged = value.groupName !== this.dialogData?.data?.['name'];
			});
			// eslint-disable-next-line dot-notation
			this.formGroup.get('groupName')?.setValue(this.dialogData?.data?.['name']);
		}

		if (this.dialogData?.data) {
			this.formGroup.patchValue(this.dialogData?.data);
		}
	}

	/**
	 * Method to trim trailing spaces for a specific control in the end
	 */
	public trimTrailingSpaces(controlName: string): void {
		const control = this.formGroup.get(controlName);
		if (control) {
			const trimmedValue = control.value?.trimEnd() || '';
			control.setValue(trimmedValue, { emitEvent: false });
		}
	}

	public doAction(): void {
		if (this.formGroup.valid) {
			this.dialogRef.close({ data: this.formGroup.value });
		}
	}

	public onSubmit(): void {
		// Trim all form controls before submission
		Object.keys(this.formGroup.controls).forEach((controlName) => {
			this.trimTrailingSpaces(controlName);
		});

		this.formGroup.markAllAsTouched();
		this.doAction();
	}

	public closeDialog(): void {
		this.dialogRef.close();
	}

	/**
	 * Lifecycle hook that is called when the component is being destroyed.
	 * It unsubscribes from observables and cleans up any resources.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
