/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
@Component({
	selector: 'sh-info-icon-renderer',
	templateUrl: './info-icon-renderer.component.html',
	styleUrls: ['./info-icon-renderer.component.scss'],
	standalone: true,
	imports: [MatIconModule, MatTooltipModule, CommonModule]
})
export class InfoIconRenderComponent {
	public params!: any;

	/**
	 * agInit - initiate the expiration cell param
	 * @param params - represent cell parameter
	 */
	public agInit(params: any): void {
		this.params = params;
	}
}
