import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@jsverse/transloco';

import { FeatureRequestMainComponent } from './feature-request-main.component';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		MatFormFieldModule,
		MatInputModule
	],
	declarations: [FeatureRequestMainComponent],
	exports: [FeatureRequestMainComponent]
})
export class FeatureRequestModule {}
