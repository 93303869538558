import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';

/**
 * Guard to check if the user's Okta session is still available.
 * If the session is not available, all tokens in the token manager
 * will be cleared. The subsequent OktaAuthGuard will handle sending
 * the user to the appropriate login page.
 *
 * @param _route - The activated route snapshot
 * @param _state - The router state snapshot
 * @returns {Promise<boolean>} Always returns true to allow navigation to proceed.
 */
export const oktaSessionGuard: CanActivateFn = async (_route, _state): Promise<boolean> => {
	const oktaAuth = inject(OKTA_AUTH);
	const exists = await oktaAuth.session.exists();
	if (exists === false) {
		oktaAuth.tokenManager.clear();
	}
	return true;
};
