export enum ResponseState {
	Done = 'Done',
	Error = 'ERROR',
	Cancel = 'Cancel'
}
export interface Response<DoneType, ErrorType> {
	state: ResponseState;
	data?: ResponseData;
	done?: DoneType;
	error?: ErrorType;
}
export interface ResponseData {
	nodeId: string;
}
export interface RequestResponse<ErrorType> {
	state: ResponseState;
	error?: ErrorType;
}

export enum ProgressState {
	Progress = 'Progress',
	Done = 'Done',
	Cancelled = 'Cancelled',
	Error = 'ERROR'
}
export interface ResponseWithProgress<DoneType, ProgressType, ErrorType> {
	state: ProgressState;
	progress?: ProgressType;
	done?: DoneType;
	error?: ErrorType;
}
