<div class="empty-state">
	<img *ngIf="!!src" [src]="src" />
	<div class="mat-headline-5" *ngIf="!!title">{{ title }}</div>

	<div class="mat-subtitle-1">
		<ng-content></ng-content>
	</div>

	<button *ngIf="!!actionTitle" mat-raised-button mat-button color="primary" (click)="onClick($event)">
		{{ actionTitle }}
	</button>
</div>
