import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';

import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import { APP_ENVIRONMENT, AppEnvironment, getShureCloudUri } from '@shure/cloud/shared/utils/config';

import { footerUrls } from './footer-urls';
import { getFormattedUrl } from './footer-utils';
import { FooterLink } from './footer.model';
@Component({
	selector: 'sh-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
	@Output() public contactUs: EventEmitter<boolean> = new EventEmitter<boolean>();
	public activeFooterLinksList!: FooterLink[];
	public localeCode: string = localStorage.getItem('locale') || 'en';
	public footerLinksList: FooterLink[] = [
		{
			label: this.translocoService.translate('cloud.shared.footer.contact'),
			id: 'contact-us',
			url: getFormattedUrl('CONTACT_US')
		},
		{
			label: this.translocoService.translate('cloud.shared.footer.shure-cloud-faq'),
			id: 'faq',
			url: footerUrls.SHURE_CLOUD_FAQ
		},
		{
			label: this.translocoService.translate('cloud.shared.footer.privacy-policy'),
			id: 'privacy',
			url: getFormattedUrl('PRIVACY_POLICY')
		},
		{
			label: this.translocoService.translate('cloud.shared.footer.terms-and-conditions'),
			id: 'terms',
			url: footerUrls.TERMS_CONDITIONS
		},
		{
			label: this.translocoService.translate('cloud.shared.footer.imprint'),
			id: 'imprint',
			url: footerUrls.IMPRINT
		},
		{
			label: this.translocoService.translate('cloud.shared.footer.cookie-policy'),
			id: 'cookie-policy',
			url: getFormattedUrl('COOKIE_POLICY')
		},
		{
			label:
				this.translocoService.translate('cloud.shared.footer.do-not-sell-my-data') +
				'/' +
				this.translocoService.translate('cloud.shared.footer.change-cookie-preference'),
			id: 'cookie',
			url: 'javascript: Cookiebot.renew()'
		}
	];
	private destroy$: Subject<void> = new Subject<void>();
	public appVersion!: string;
	public currentYear: number = new Date().getFullYear();
	public readonly isXSmallDevice = this.breakpointService.isLteXSmall;
	public readonly isSmallDevice = this.breakpointService.isLteSmall;
	constructor(
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		private translocoService: TranslocoService,
		private router: Router,
		private readonly breakpointService: BreakpointService
	) {}

	public ngOnInit(): void {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEvent) => {
			if (event instanceof NavigationEnd) {
				this.setFooterLinks(event.url);
			}
		});
		this.setFooterLinks(this.router.url);
	}

	/**
	 * Handle click event for the footer links and  redirect to page
	 * @param footerLink - footerLink which is clicked
	 */
	public onFooterLinkClick(footerLink: FooterLink): void {
		if (footerLink.id === 'cookie' && footerLink.url) {
			window.location.href = footerLink.url;
		} else if (footerLink.id === 'imprint') {
			this.routeToImprint();
		} else if (footerLink.id === 'contact-us') {
			this.contactUs.emit(true);
			window.open(footerLink.url);
		} else window.open(footerLink.url);
	}

	/**
	 * Route to correct imprint link
	 */
	public routeToImprint(): void {
		const shureCloudUri = getShureCloudUri(window.location.origin, this.appEnv.shureCloudUriMap) ?? '';
		window.open(`${shureCloudUri}/imprint`);
	}

	/**
	 * Handle footer links in all pages
	 * @param url - active url in the tab
	 */
	public setFooterLinks(url: string): void {
		if (url === '/home') {
			this.activeFooterLinksList = this.footerLinksList.slice(2);
		} else {
			this.activeFooterLinksList = this.footerLinksList;
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
