import { ValidatorFn } from '@angular/forms';

export interface AccountInfoDto {
	department?: string;
	emailPreferences?: {
		louderMusician?: boolean;
		louderContentCreator?: boolean;
		trainingCommunication?: boolean;
		marketingCommunication?: boolean;
	};
	firstName?: string;
	interests?: Array<string>;
	jobTitle?: string;
	language?: string;
	lastName?: string;
	optOutOfEmail?: boolean;
	region?: string;
	relationToShure?: Array<string>;
	removeProfileImage?: boolean;
	segment?: string;
	segmentDetails?: string;
	tc?: {
		isTcAccepted?: boolean;
	};
	timeZone?: string;
	workPhone?: string;
	mobilePhone?: string;
}

export interface ProgProfileFormDataDetails {
	label: string;
	name: string;
	validators: ValidatorFn[] | [];
	type: string;
	errorLabel?: string;
	data?: { value: string; label: string }[];
	disabled?: boolean;
	token?: string;
}

export interface ProgProfileDataResponse {
	data: AccountInfoDto;
}

export const relationshipOptions = [
	{ name: 'Affiliate', value: 'Affiliate', label: 'cloud.shared.shure-relationship.affiliate' },
	{ name: 'Influencer', value: 'Influencer', label: 'cloud.shared.shure-relationship.influencer' },
	{ name: 'B2bReseller', value: 'B2B Reseller', label: 'cloud.shared.shure-relationship.b2b-reseller' },
	{ name: 'ItReseller', value: 'IT Reseller', label: 'cloud.shared.shure-relationship.it-reseller' },
	{ name: 'Consultant', value: 'Consultant', label: 'cloud.shared.shure-relationship.consultant' },
	{ name: 'Other', value: 'Other', label: 'cloud.shared.shure-relationship.other' },
	{ name: 'Dealer', value: 'Dealer', label: 'cloud.shared.shure-relationship.dealer' },
	{ name: 'Distributor', value: 'Distributor', label: 'cloud.shared.shure-relationship.distributor' },
	{ name: 'EndUser', value: 'End-User', label: 'cloud.shared.shure-relationship.end-user' },
	{ name: 'RentalCompany', value: 'Rental Company', label: 'cloud.shared.shure-relationship.rental-company' },
	{ name: 'ShureRepFirm', value: 'Sales Rep', label: 'cloud.shared.shure-relationship.shure-rep-firm' },
	{
		name: 'SystemIntegrator',
		value: 'System Integrator',
		label: 'cloud.shared.shure-relationship.system-integrator'
	},
	{ name: 'Freelancer', value: 'Freelancer', label: 'cloud.shared.shure-relationship.freelancer' }
];

export const industryOptions = [
	{
		value: 'Corporate',
		label: 'cloud.shared.segment-labels.corporate',
		industryDetail: [
			{ value: 'Amusement/Leisure Park', label: 'cloud.shared.segment-details-labels.amusement-leisure-park' },
			{ value: 'Arts/Entertainment', label: 'cloud.shared.segment-details-labels.arts-entertainment' },
			{ value: 'Banking/Finance', label: 'cloud.shared.segment-details-labels.banking-finance' },
			{
				value: 'Construction/Architecture',
				label: 'cloud.shared.segment-details-labels.construction-architecture'
			},
			{ value: 'Energy/Utilities', label: 'cloud.shared.segment-details-labels.energy-utilities' },
			{ value: 'Engineering/Design', label: 'cloud.shared.segment-details-labels.engineering-design' },
			{
				value: 'Facilities Management/Real Estate',
				label: 'cloud.shared.segment-details-labels.facilities-management-real-estate'
			},
			{ value: 'Healthcare', label: 'cloud.shared.segment-details-labels.healthcare' },
			{
				value: 'Hospitality/Travel/Tourism',
				label: 'cloud.shared.segment-details-labels.hospitality-travel-tourism'
			},
			{ value: 'Manufacturing/FMCG', label: 'cloud.shared.segment-details-labels.manufacturing-fmcg' },
			{ value: 'Non-Profit', label: 'cloud.shared.segment-details-labels.non-profit' },
			{
				value: 'Professional Services/Consulting',
				label: 'cloud.shared.segment-details-labels.professional-services-consulting'
			},
			{
				value: 'Professional/Industry Association',
				label: 'cloud.shared.segment-details-labels.professional-industry-association'
			},
			{ value: 'Retail', label: 'cloud.shared.segment-details-labels.retail' },
			{ value: 'Technology', label: 'cloud.shared.segment-details-labels.technology' },
			{ value: 'Transport/Logistics', label: 'cloud.shared.segment-details-labels.transport-logistics' }
		]
	},
	{
		value: 'Education',
		label: 'cloud.shared.segment-labels.education',
		industryDetail: [
			{
				value: 'Community College/Vocational',
				label: 'cloud.shared.segment-details-labels.community-college-vocational'
			},
			{
				value: 'Primary/Elementary/Junior High',
				label: 'cloud.shared.segment-details-labels.primary-elementary-junior-high'
			},
			{ value: 'Secondary/High School', label: 'cloud.shared.segment-details-labels.secondary-high-school' },
			{
				value: 'Training Center/Professional Certification',
				label: 'cloud.shared.segment-details-labels.training-center-professional-certification'
			},
			{ value: 'University', label: 'cloud.shared.segment-details-labels.university' }
		]
	},
	{
		value: 'Government',
		label: 'cloud.shared.segment-labels.government',
		industryDetail: [
			{ value: 'Emergency Services', label: 'cloud.shared.segment-details-labels.emergency-services' },
			{ value: 'Environmental', label: 'cloud.shared.segment-details-labels.environmental' },
			{ value: 'Federal/National', label: 'cloud.shared.segment-details-labels.federal-national' },
			{ value: 'Government Agency', label: 'cloud.shared.segment-details-labels.government-agency' },
			{
				value: 'Health & Human Services',
				label: 'cloud.shared.segment-details-labels.health-and-human-services'
			},
			{ value: 'Military', label: 'cloud.shared.segment-details-labels.military' },
			{ value: 'NGO', label: 'cloud.shared.segment-details-labels.ngo' },
			{ value: 'Regional/County/Local', label: 'cloud.shared.segment-details-labels.regional-county-local' },
			{ value: 'State/Metropolitan', label: 'cloud.shared.segment-details-labels.state-metropolitan' },
			{
				value: 'Transportation & Infrastructure',
				label: 'cloud.shared.segment-details-labels.transportation-and-infrastructure'
			}
		]
	},
	{
		value: 'Broadcast',
		label: 'cloud.shared.segment-labels.broadcast',
		industryDetail: [
			{ value: 'ENG', label: 'eng' },
			{ value: 'Film/Location Sound', label: 'cloud.shared.segment-details-labels.film-location-sound' },
			{ value: 'National Broadcaster', label: 'cloud.shared.segment-details-labels.national-broadcaster' },
			{ value: 'Radio', label: 'radio' },
			{ value: 'Regional Broadcaster', label: 'cloud.shared.segment-details-labels.regional-broadcaster' },
			{ value: 'Sports/Event Production', label: 'cloud.shared.segment-details-labels.sports-event-production' }
		]
	},
	{
		value: 'Live Sound & Events',
		label: 'cloud.shared.segment-labels.live-sound-and-events',
		industryDetail: [
			{ value: 'Convention Center', label: 'cloud.shared.segment-details-labels.convention-center' },
			{ value: 'Cruise Ship', label: 'cloud.shared.segment-details-labels.cruise-ship' },
			{ value: 'Event Management', label: 'cloud.shared.segment-details-labels.event-management' },
			{ value: 'House of Worship', label: 'cloud.shared.segment-details-labels.house-of-worship' },
			{ value: 'Performance Venue', label: 'cloud.shared.segment-details-labels.performance-venue' },
			{
				value: 'Recording Studio/Studio Production',
				label: 'cloud.shared.segment-details-labels.recording-studio-studio-production'
			},
			{ value: 'Rental Company', label: 'cloud.shared.segment-details-labels.rental-company' },
			{ value: 'Stadium', label: 'cloud.shared.segment-details-labels.stadium' },
			{ value: 'Touring Sound', label: 'cloud.shared.segment-details-labels.touring-sound' }
		]
	},
	{
		value: 'Theater',
		label: 'cloud.shared.segment-labels.theater',
		industryDetail: [
			{ value: 'Broadway/West End', label: 'cloud.shared.segment-details-labels.broadway-west-end' },
			{
				value: 'Community/Regional Theatre',
				label: 'cloud.shared.segment-details-labels.community-regional-theatre'
			},
			{ value: 'National Theatre', label: 'cloud.shared.segment-details-labels.national-theatre' },
			{ value: 'University Theatre', label: 'cloud.shared.segment-details-labels.university-theatre' }
		]
	},
	{
		value: 'Artist/Musician',
		label: 'cloud.shared.segment-labels.artist-or-musician',
		industryDetail: [
			{
				value: 'Managing/Marketing Agency',
				label: 'cloud.shared.segment-details-labels.managing-marketing-agency'
			},
			{ value: 'Performer/Band', label: 'cloud.shared.segment-details-labels.performer-band' },
			{ value: 'Producer', label: 'cloud.shared.segment-details-labels.producer' },
			{ value: 'Record Label', label: 'cloud.shared.segment-details-labels.record-label' },
			{ value: 'School/Instruction', label: 'cloud.shared.segment-details-labels.school-instruction' }
		]
	},
	{
		value: 'Content Creator',
		label: 'cloud.shared.segment-labels.content-creator',
		industryDetail: [
			{ value: 'Content Distribution', label: 'cloud.shared.segment-details-labels.content-distribution' },
			{ value: 'Content Production', label: 'cloud.shared.segment-details-labels.content-production' },
			{ value: 'Influencer', label: 'cloud.shared.segment-details-labels.influencer' },
			{ value: 'Mobile Journalism', label: 'cloud.shared.segment-details-labels.mobile-journalism' }
		]
	},
	{
		value: 'Gaming',
		label: 'cloud.shared.segment-labels.gaming',
		industryDetail: [
			{ value: 'Console Gaming', label: 'cloud.shared.segment-details-labels.console-gaming' },
			{ value: 'Mobile Gaming', label: 'cloud.shared.segment-details-labels.mobile-gaming' },
			{ value: 'PC Gaming', label: 'cloud.shared.segment-details-labels.pc-gaming' },
			{ value: 'Streaming', label: 'cloud.shared.segment-details-labels.streaming' }
		]
	},
	{
		value: 'Listening',
		label: 'cloud.shared.segment-labels.listening',
		industryDetail: [
			{ value: 'Audiophile Listening', label: 'cloud.shared.segment-details-labels.audiophile-listening' }
		]
	}
];
export const interestsOptions = [
	{ name: 'listening', value: 'Listening', label: 'cloud.shared.interests-labels.listening' },
	{ name: 'performance', value: 'Performance', label: 'cloud.shared.interests-labels.performance' },
	{ name: 'contentCreation', value: 'Content Creation', label: 'cloud.shared.interests-labels.content-creation' },
	{
		name: 'professionalProduction',
		value: 'Professional Production',
		label: 'cloud.shared.interests-labels.professional-production'
	},
	{ name: 'houseOfWorship', value: 'House of Worship', label: 'cloud.shared.interests-labels.house-of-worship' },
	{ name: 'theater', value: 'Theater', label: 'cloud.shared.interests-labels.theater' },
	{ name: 'broadcast', value: 'Broadcast', label: 'cloud.shared.interests-labels.broadcast' },
	{
		name: 'avConferencingAndMeetings',
		value: 'AV Conferencing and Meetings',
		label: 'cloud.shared.interests-labels.av-conferencing-and-meetings'
	}
];
