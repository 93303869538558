import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'inputDeviceType' })
export class InputDeviceTypePipe implements PipeTransform {
	public transform(value: string): string {
		switch (value) {
			case 'MXA901_ROUND_IMX': {
				return 'MXA901_IMX';
			}
			case 'MXA920_ROUND': {
				return 'MXA920';
			}
			case 'MXA920_ROUND_IMX': {
				return 'MXA920_IMX';
			}
			case 'Unknown': {
				return '---';
			}
			default: {
				return value;
			}
		}
	}
}
