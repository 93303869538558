import { Injectable, Signal, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';

import { IBreadcrumb } from '../breadcrumbs';

@Injectable({ providedIn: 'root' })
export class HeaderToolbarService {
	private breadcrumb$ = new BehaviorSubject<IBreadcrumb[]>([]);
	public getImageUrl$ = new BehaviorSubject<string>('');
	public isImageRemoved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public profileChars$ = new BehaviorSubject<string>('');
	private headerSignal = signal('');

	constructor(private organizationsStoreService: OrganizationsStoreService) {}

	/**
	 * Sets the profile character data for the current component/page.
	 * @param profChars - An string of character items to set.
	 */
	public setProfileChars(profileChars: { firstName: string; lastName: string }): void {
		this.organizationsStoreService.orderOfNamesList$.subscribe((orderOfNames) => {
			if (orderOfNames?.[0]?.id === 'lastName') {
				this.profileChars$.next(
					(profileChars.lastName.charAt(0) + profileChars.firstName.charAt(0)).toUpperCase()
				);
			} else {
				this.profileChars$.next(
					(profileChars.firstName.charAt(0) + profileChars.lastName.charAt(0)).toUpperCase()
				);
			}
		});
	}

	/**
	 * Sets the breadcrumb data for the current component/page.
	 * @param breadcrumb - An array of breadcrumb items to set.
	 */
	public setBreadCrumb(breadcrumb: IBreadcrumb[]): void {
		this.breadcrumb$.next(breadcrumb);
	}

	/**
	 * returns the breadcrumb data as an Observable stream.
	 * @returns An Observable of an array of breadcrumb items.
	 */
	public getBreadCrumb$(): Observable<IBreadcrumb[]> {
		return this.breadcrumb$;
	}

	/**
	 * Sets the breadcrumb data for the current component/page.
	 * @param breadcrumb - An array of breadcrumb items to set.
	 */
	public setHeader(header: string): void {
		this.headerSignal.set(header);
	}

	/**
	 * returns the breadcrumb data as an Observable stream.
	 * @returns An Observable of an array of breadcrumb items.
	 */
	public getHeader(): Signal<string> {
		return this.headerSignal;
	}
}
