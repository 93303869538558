<div id="main-div" *transloco="let t">
	<div id="side-nav-title">
		<a id="side-nav-title-link" [routerLink]="navigateTo"
			>{{ t('cloud.shared.message-center-side-nav.shure-cloud') }}
			<mat-icon fontSet="prism-mat" fontIcon="open_in_new" id="new-tab-icon" slot="end"></mat-icon>
		</a>
	</div>
	<mat-divider></mat-divider>
	<div id="message-center-nav-div">
		<span id="message-center-nav-title-span">{{ t('cloud.shared.message-center-side-nav.title') }}</span>
	</div>
	<mat-nav-list>
		<mat-list-item
			[routerLink]="['notifications-list']"
			[routerLinkActiveOptions]="{ exact: true }"
			routerLinkActive="mat-menu-item-active"
		>
			<mat-icon fontSet="prism-mat" fontIcon="mail" id="message-center-nav-inbox-icon"></mat-icon>
			<span class="mat-list-text" id="message-center-nav-inbox-span">{{
				t('cloud.shared.message-center-side-nav.inbox')
			}}</span>
		</mat-list-item>
		<mat-list-item [routerLink]="['notifications-list/trash']" routerLinkActive="mat-menu-item-active">
			<mat-icon fontSet="prism-mat" fontIcon="delete" id="message-center-nav-trash-icon"></mat-icon>
			<span class="mat-list-text" id="message-center-nav-trash-span">{{
				t('cloud.shared.message-center-side-nav.trash')
			}}</span>
		</mat-list-item>
	</mat-nav-list>
</div>
