import { proxyCustomElement, HTMLElement, h, Host } from '@stencil/core/internal/client';

const prismGraduatedContainerCss = ":host{display:flex;--_padding:var(--prism-graduated-container-padding, 0px)}:host([orientation=horizontal]){flex-direction:column}.pwc-scale-wrapper.has-slider.vertical{padding-block:var(--_padding)}.pwc-scale-wrapper.has-slider.horizontal{padding-inline:var(--_padding)}";
const PrismGraduatedContainerStyle0 = prismGraduatedContainerCss;

const PrismGraduatedContainer$1 = /*@__PURE__*/ proxyCustomElement(class PrismGraduatedContainer extends HTMLElement {
    constructor() {
        super();
        this.__registerHost();
        this.__attachShadow();
        this.ticks = [];
        this.orientation = 'vertical';
        this.fader = false;
    }
    render() {
        return (h(Host, { key: '87fe55b8fb64bd843fb731852896b4656311935a' }, h("div", { key: '92620d01503370ef24202033b9e6e55cf644273e', class: `pwc-scale-wrapper ${this.orientation}${this.fader ? ' fader' : ''}`, ref: (el) => (this.wrapperBefore = el) }, h("slot", { key: 'd7315fc4b7aa7249273baf7d4059e4e7a2574077', name: "scale-before", onSlotchange: (e) => this.scaleSlotChange(e, true) })), h("slot", { key: 'f1a7bdce8e8d6bf95facdd0fe5f044e750ef8889', onSlotchange: (e) => this.mainSlotChange(e) }), h("div", { key: '9e2209eb3af8128198497b56a32818f2b0a72d45', class: `pwc-scale-wrapper after ${this.orientation}${this.fader ? ' fader' : ''}`, ref: (el) => (this.wrapperAfter = el) }, h("slot", { key: '65ec048e7ffde25a9c1740220641ce3de0b669e1', name: "scale-after", onSlotchange: (e) => this.scaleSlotChange(e, false) }))));
    }
    scaleSlotChange(event, before) {
        if (event.target.assignedElements()[0].tagName === 'PRISM-SCALE') {
            const scale = event.target.assignedElements()[0];
            scale.orientation = this.orientation;
            this.ticks = scale.ticks;
            if (before) {
                scale.position = this.orientation === 'vertical' ? 'left' : 'top';
            }
            else {
                scale.position = this.orientation === 'vertical' ? 'right' : 'bottom';
            }
        }
    }
    mainSlotChange(event) {
        const slottedElement = event.target.assignedElements()[0];
        if (slottedElement && slottedElement.tagName === 'PRISM-SLIDER') {
            this.sliderElement = slottedElement;
            this.fader = this.sliderElement.fader;
            this.wrapperBefore.classList.add('has-slider');
            this.wrapperAfter.classList.add('has-slider');
            if (this.ticks) {
                this.sliderElement.ticks = this.ticks;
            }
            const computedStyle = getComputedStyle(this.hostElement, null);
            const customPadding = computedStyle.getPropertyValue('--prism-graduated-container-padding');
            const padding = customPadding ? customPadding : '0px';
            if (this.orientation === 'vertical') {
                this.sliderElement.style.setProperty('--prism-slider-padding-inline', 'var(--prism-spacing-global-medium)');
                this.sliderElement.style.setProperty('--prism-slider-padding-block', padding);
            }
            else {
                this.sliderElement.style.setProperty('--prism-slider-padding-inline', padding);
                this.sliderElement.style.setProperty('--prism-slider-padding-block', 'var(--prism-spacing-global-medium)');
            }
        }
    }
    get hostElement() { return this; }
    static get style() { return PrismGraduatedContainerStyle0; }
}, [1, "prism-graduated-container", {
        "orientation": [1],
        "fader": [4]
    }]);
function defineCustomElement$1() {
    if (typeof customElements === "undefined") {
        return;
    }
    const components = ["prism-graduated-container"];
    components.forEach(tagName => { switch (tagName) {
        case "prism-graduated-container":
            if (!customElements.get(tagName)) {
                customElements.define(tagName, PrismGraduatedContainer$1);
            }
            break;
    } });
}

const PrismGraduatedContainer = PrismGraduatedContainer$1;
const defineCustomElement = defineCustomElement$1;

export { PrismGraduatedContainer, defineCustomElement };

