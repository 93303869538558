<div
	id="sh-request-business-dialog-container-div"
	*transloco="let t; read: 'cloud.shure-cloud.request-business-account'"
>
	@if (formGroup) {
		<form [formGroup]="formGroup">
			<h1 id="sh-request-business-title-h1">
				{{ t('request-business-account') }}
			</h1>
			<div id="sh-request-business-confirm-text-div">
				{{ t('request-business-account-content') }}
			</div>
			<mat-form-field id="sh-request-business-name-field">
				<mat-label id="sh-request-business-name-mat-label">{{ t('organization-name') }}</mat-label>
				<input
					id="sh-request-business-name-mat-input"
					[placeholder]="t('organization-name')"
					matInput
					formControlName="organizationName"
				/>

				@if (
					(formGroup.controls['organizationName'].hasError('required') ||
						formGroup.controls['organizationName'].hasError('whitespace')) &&
					formGroup.controls['organizationName'].touched
				) {
					<mat-error>{{ 'cloud.shared.error-labels.organization-name-required' | transloco }} </mat-error>
				}
				@if (
					formGroup.controls['organizationName'].hasError('maxlength') &&
					formGroup.controls['organizationName'].touched
				) {
					<mat-error>
						{{ 'cloud.shared.error-labels.must-be-max-length' | transloco: { number: 150 } }}
					</mat-error>
				}
			</mat-form-field>
			<mat-form-field id="sh-request-business-company-name-field">
				<mat-label id="sh-request-business-company-name-mat-label">{{ t('company-name') }}</mat-label>
				<input
					id="sh-request-business-company-name-mat-input"
					[placeholder]="t('company-name')"
					matInput
					formControlName="companyName"
				/>
				@if (
					(formGroup.controls['companyName'].hasError('required') ||
						formGroup.controls['companyName'].hasError('whitespace')) &&
					formGroup.controls['companyName'].touched
				) {
					<mat-error>{{ 'cloud.shared.error-labels.company-name-required' | transloco }} </mat-error>
				}
			</mat-form-field>
			<div>
				<mat-checkbox [formControlName]="'useContactInfo'">
					{{ t('use-contact-information-text') }}
				</mat-checkbox>
			</div>
			<div id="sh-request-business-action-buttons-div">
				<button mat-button (click)="closeDialog()" id="sh-request-business-button-cancel">
					{{ t('cancel') }}
				</button>
				<button
					mat-button
					[disabled]="formGroup.invalid"
					(click)="submitRequestVerification()"
					id="sh-request-business-send-request-button"
				>
					{{ t('send-request') }}
				</button>
			</div>
		</form>
	}
</div>
