/**
 * Get the overlay div. If the div doesn't exist, null will be returned. If the div should be created if it doesn't
 * exist, this can be specified with the options object.
 *
 * @param options options to configure the behavior of the getter.
 *   - `createIfNonexistent` Whether to create a new overlay div if it doesn't already exist.
 * @returns {HTMLDivElement} the overlay div or null
 */
function getOverlay(options) {
    var _a;
    // If there is no parent passed in, or if the passed-in parent is falsy, use document.body.
    const parent = (_a = options === null || options === void 0 ? void 0 : options.parent) !== null && _a !== void 0 ? _a : document.body;
    let overlay = parent.querySelector('#pwc-overlay');
    if (overlay === null && (options === null || options === void 0 ? void 0 : options.createIfNonexistent)) {
        overlay = document.createElement('div');
        overlay.classList.add('pwc-overlay');
        overlay.id = 'pwc-overlay';
        parent.insertBefore(overlay, parent.firstChild);
    }
    return overlay;
}
/**
 * Add a given HTML node to the overlay. This means that the node will be shown on top of all other elements on the
 * page regardless of their z-index and DOM placement.
 *
 * @param node {Node} the HTML node that should be shown in the overlay
 * @returns {HTMLDivElement} the overlay div that the node has been added to
 */
function addToOverlay(node) {
    // A special case for <prism-dialog>, which uses the <dialog> element,
    // and therefore the browser’s built-in top layer.
    let closestDialog = closestPassShadow(node, 'prism-dialog');
    if (closestDialog) {
        // Store a reference to the closest dialog on the element we'll be putting in the overlay
        // so we can access it later. This is, admittedly, hacky.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        node.__initialClosestDialog = closestDialog;
    }
    else {
        // Try to get a previously stored closest dialog. If there isn't one, it'll be undefined.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closestDialog = node.__initialClosestDialog;
    }
    const overlay = getOverlay({ createIfNonexistent: true, parent: closestDialog });
    overlay.appendChild(node);
    return overlay;
}
/**
 * Remove a given HTML node from the overlay. This means that the given node will no longer be shown on top of all other
 * element on the page regardless of their z-index and DOM placement.
 *
 * @param node {Node} The node that is shown as in the overlay which should be removed
 */
function removeFromOverlay(node) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const overlay = getOverlay({ parent: node.__initialClosestDialog });
    if (!overlay)
        return;
    if (overlay.contains(node)) {
        overlay.removeChild(node);
    }
    if (!overlay.hasChildNodes()) {
        overlay.remove();
    }
}
/**
 * The Element.closest() method does not traverse shadow boundaries. This recursive method (h/t StackOverflow) does.
 * https://stackoverflow.com/a/67676665
 */
function closestPassShadow(node, selector) {
    if (!node) {
        return null;
    }
    if (node instanceof ShadowRoot) {
        return closestPassShadow(node.host, selector);
    }
    if (node instanceof HTMLElement) {
        if (node.matches(selector)) {
            return node;
        }
        return closestPassShadow(node.parentNode, selector);
    }
    return closestPassShadow(node.parentNode, selector);
}

export { addToOverlay as a, getOverlay as g, removeFromOverlay as r };

