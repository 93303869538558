import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output, Self } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ControlValueAccessor, NgControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subject } from 'rxjs';

@Component({
	providers: [
		{
			provide: MatFormFieldControl,
			useExisting: TextInputComponent
		}
	],
	selector: 'sh-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	standalone: true,
	imports: [CommonModule, FlexLayoutModule, MatInputModule, ReactiveFormsModule]
})
export class TextInputComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy {
	/**
	 * Id counter for assigning default id.
	 *
	 * @ignore internal.
	 */
	public static nextId = 0;

	/**
	 * Accessibility.
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	@HostBinding('attr.aria-describedby')
	public describedBy = '';

	/**
	 * Toggle whether the label should float.
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	@HostBinding('class.floating')
	public get shouldLabelFloat(): boolean {
		return this.focused || !this.empty;
	}

	@Output()
	public readonly changed = new EventEmitter<string>();

	@Input()
	public id = `sh-text-input-${TextInputComponent.nextId++}`;

	@Input()
	public set value(input: string) {
		this.ngControl.control?.markAsUntouched();

		if (!this.invalidValue) {
			this.previousValue = input;
		}

		this.displayValue = input;
		this.stateChanges.next();
	}
	public get value(): string {
		return this.ngControl.value;
	}

	@Input()
	public set placeholder(placeholder: string) {
		this._placeholder = placeholder;
		this.stateChanges.next();
	}
	public get placeholder(): string {
		return this._placeholder;
	}
	private _placeholder!: string;

	@Input()
	public set disabled(disabled: boolean) {
		this._disabled = disabled;
		this.stateChanges.next();
	}
	public get disabled(): boolean {
		return this._disabled;
	}
	private _disabled = false;

	@Input()
	public set readonly(readonly: boolean) {
		this._readonly = readonly;
		this.stateChanges.next();
	}
	public get readonly(): boolean {
		return this._readonly;
	}
	private _readonly = false;

	@Input()
	public get required(): boolean {
		return this._required;
	}
	public set required(req) {
		this._required = req;
		this.stateChanges.next();
	}
	private _required = false;

	@Input()
	public get minLength(): number {
		return this._minLength;
	}
	public set minLength(length: number) {
		if (length) {
			this._minLength = length;
		}
	}
	private _minLength!: number;

	@Input()
	public get maxLength(): number {
		return this._maxLength;
	}
	public set maxLength(length: number) {
		if (length) {
			this._maxLength = length;
		}
	}
	private _maxLength!: number;

	@Input()
	public get errorStateMatcher(): ErrorStateMatcher {
		return this._errorStateMatcher;
	}
	public set errorStateMatcher(matcher: ErrorStateMatcher) {
		if (matcher) {
			this._errorStateMatcher = matcher;
		}
	}
	private _errorStateMatcher!: ErrorStateMatcher;

	/**
	 * Property for when the label should be floating
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	public get empty(): boolean {
		return this.value == null;
	}

	/**
	 * Applies the ```mat-form-field-type-{controlType}```class to the
	 * ```<mat-form-field>```.
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	public readonly controlType = 'text-input';

	/**
	 * Flag for toggling focus state of input
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	public focused = false;

	/**
	 * $ for notifying ```<mat-form-field>``` of changes.
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	public readonly stateChanges = new Subject<void>();

	public get errorState(): boolean {
		return !!this.ngControl.invalid && !!this.ngControl.touched;
	}

	public displayValue = '';
	public previousValue!: string;
	private invalidValue = false;

	constructor(@Self() public ngControl: NgControl) {
		this.ngControl.valueAccessor = this;
	}

	/**
	 * Updates displayed value without overriding form control value until changes are
	 * submitted via enter keypress or input element blur.
	 *
	 * @ignore internal.
	 */
	public updateDisplayValue(input: string): void {
		this.displayValue = input;
	}

	/**
	 * Reverts all changes made while input element was first focused.
	 *
	 * @ignore internal.
	 */
	public undoChanges(): void {
		this.displayValue = this.previousValue;
	}

	/**
	 * Called by NgControl when value is changed from the outside.
	 * If invalid input is entered and user clicks/tabs away from the input element,
	 * reverts the input element value to last valid form value.
	 * @ignore required by ControlValueAccessor.
	 */
	public writeValue(value: string | null): void {
		if (this.errorState && this.ngControl.touched) {
			this.value = this.previousValue;
			return;
		}

		if (value !== null) {
			this.value = value;
		}
	}

	/**
	 * Submits updated input element value to form.
	 * First, form value is patched to check validity of updated value.
	 * If no errors are found, emits changed value; else reverts to last valid form value.
	 * @ignore internal.
	 */
	public updateFormValue(input: string | null): void {
		this.focused = false;

		if (input === this.previousValue) {
			this.stateChanges.next();
			return;
		}

		const resetValue = this.previousValue;
		this.ngControl.control?.patchValue(input);

		if (input !== null && this.ngControl.control?.errors === null) {
			this.invalidValue = false;
			this.displayValue = input;
			this.changed.emit(input);
		} else {
			this.invalidValue = true;
			this.previousValue = resetValue;
			this.value = input || '';
		}

		this.onTouch();
		this.stateChanges.next();
	}

	/**
	 * Triggers focus state of input and notifies ```<mat-form-field>``` of the state change
	 */
	public focus(): void {
		this.focused = true;
		this.stateChanges.next();
	}

	public ngOnDestroy(): void {
		this.stateChanges.complete();
	}

	// METHODS BELOW ARE REQUIRED BY CONTROL VALUE ACCESSOR / FORM FIELD CONTROL:
	/**
	 * Register a callback for notifying the NgControl when value is changed by
	 * this component.
	 *
	 * @ignore required by ControlValueAccessor.
	 */
	public registerOnChange(fn: () => void): void {
		this.onChange = fn;
	}

	/**
	 * Register a callback for notifying the NgControl when the input has been
	 * interacted with.
	 *
	 * @ignore required by ControlValueAccessor
	 */
	public registerOnTouched(fn: () => void): void {
		this.onTouch = fn;
	}

	/**
	 * Called by NgControl when the form is enabled/disabeld by .enable() or .disable().
	 *
	 * @ignore required by ControlValueAccessor
	 */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	/**
	 * Accessibility.
	 *
	 * @ignore required by MatFormFieldControl
	 */
	public setDescribedByIds(ids: string[]): void {
		this.describedBy = ids.join(' ');
	}

	/**
	 * Called by ```<mat-form-field>``` when the container is clicked.
	 *
	 * @ignore required by MatFormFieldControl.
	 */
	public onContainerClick(_event: MouseEvent): void {
		/* NOOP */
	}

	public onChange = (_value: string): void => {
		/* NOOP */
	};
	public onTouch = (): void => {
		/* NOOP */
	};
}
