import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CenterCardLayoutComponent } from './center-card-layout.component';

@NgModule({
	imports: [CommonModule],
	declarations: [CenterCardLayoutComponent],
	exports: [CenterCardLayoutComponent]
})
export class CenterCardLayoutModule {}
