import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DebugModeService {
	private isDebugModeEnabled = new BehaviorSubject(false);
	private isDebugModeCheckboxDisplayed = new BehaviorSubject(false);

	/**
	 * gets the debug mode status as observable
	 * @returns Observable that provides debug mode status
	 */
	public getDebugModeStatus$(): Observable<boolean> {
		return this.isDebugModeEnabled.asObservable();
	}

	/**
	 * sets the debug mode status
	 * @param isDebugMode - boolean representation of debug mode status
	 */
	public setDebugModeStatus$(isDebugMode: boolean): void {
		this.isDebugModeEnabled.next(isDebugMode);
	}

	/**
	 * gets the display debug mode checkbox status as observable
	 * @returns Observable that provides debug mode checkbox status
	 */
	public getDebugModeCheckboxDisplayStatus$(): Observable<boolean> {
		return this.isDebugModeCheckboxDisplayed.asObservable();
	}

	/**
	 * sets the debug mode checkbox status
	 * @param isDebugModeCheckboxEnabled - boolean representation of debug mode status
	 */
	public setDebugModeCheckboxDisplayStatus$(isDebugModeCheckboxEnabled: boolean): void {
		this.isDebugModeCheckboxDisplayed.next(isDebugModeCheckboxEnabled);
	}
}
