import { CommonModule } from '@angular/common';
import { Component, Inject, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@jsverse/transloco';

import { OrganizationDetails } from '@shure/cloud/shared/users/data-access';

import { noWhitespaceValidator } from './utils/name.validator';

@Component({
	selector: 'sh-request-business-account-main',
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatCheckboxModule
	],
	templateUrl: './request-business-account-main.component.html',
	styleUrl: './request-business-account-main.component.scss'
})
export class RequestBusinessAccountMainComponent implements OnInit {
	public readonly formGroup = new FormGroup({
		organizationName: new FormControl('', [
			noWhitespaceValidator(),
			Validators.required,
			Validators.maxLength(150)
		]),
		companyName: new FormControl('', [Validators.required, noWhitespaceValidator()]),
		useContactInfo: new FormControl(true, [Validators.requiredTrue])
	});

	constructor(
		private dialogRef: MatDialogRef<RequestBusinessAccountMainComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: OrganizationDetails
	) {}

	// HostListener to listen for the "Enter" keypress and trigger form validation
	@HostListener('window:keydown.enter', ['$event'])
	public handleEnterKey(event: KeyboardEvent): void {
		event.preventDefault();
		this.submitRequestVerification();
	}

	public ngOnInit(): void {
		this.setFormValue();
	}

	public setFormValue(): void {
		this.formGroup.patchValue({ ...this.dialogData });
	}

	public closeDialog(): void {
		this.dialogRef.close();
	}

	public submitRequestVerification(): void {
		if (this.formGroup.valid) {
			this.dialogRef.close(this.formGroup.value);
		}
	}
}
