import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface SecondaryNavList {
	listItem: string;
	path: string;
	feature: string;
	id: string;
	key: string;
	keyInParams?: boolean;
	icon?: string;
	title?: string;
	translationKey?: string;
}
export enum ToggleSecondayNavList {
	Licenses = 'licenses',
	OrganizationSettings = 'organization-settings',
	NotificationPreferences = 'notification-preferences'
}
@Injectable({
	providedIn: 'root'
})
export class SecondaryNavService {
	private toggleSecondaryNav$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private isSidenavOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private activeListItem$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private activeListItem = '';
	private isPermissionAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private secondaryNavListItems: SecondaryNavList[] = [];
	private secondaryNavListItems$: BehaviorSubject<SecondaryNavList[]> = new BehaviorSubject<SecondaryNavList[]>([]);
	private requestBusinessPermission = false;

	/**
	 * Set secondary nav list items
	 * @param secondaryNavListItems - array representation of list items
	 */
	public setSecondaryNavListItems(secondaryNavListItems: SecondaryNavList[]): void {
		this.secondaryNavListItems$.next(secondaryNavListItems);
		this.secondaryNavListItems = secondaryNavListItems;
	}

	public openSidenav(): void {
		this.isSidenavOpen$.next(true);
	}

	public closeSidenav(): void {
		this.isSidenavOpen$.next(false);
	}
	/**
	 * Get secondary nav list items as an observable
	 * @returns Observable of secondaryNavListItems
	 */
	public getSecondaryNavListItemsObs$(): Observable<SecondaryNavList[]> {
		return this.secondaryNavListItems$.asObservable();
	}

	/**
	 * Get secondary nav list items
	 * @returns secondaryNavListItems - array representation of list items
	 */
	public getSecondaryNavListItems$(): Observable<SecondaryNavList[]> {
		return of(this.secondaryNavListItems);
	}

	/**
	 * Set toggle secondary header
	 * @param toggleSecondaryNav - string representation of toggle secondary header
	 */
	public setToggleSecondaryNav(toggleSecondaryNav: string): void {
		this.toggleSecondaryNav$.next(toggleSecondaryNav);
	}

	/**
	 * Get toggle secondary header
	 * @returns toggleSecondaryNav - string representation of toggle secondary header
	 */
	public getToggleSecondaryNav$(): Observable<string> {
		return this.toggleSecondaryNav$;
	}

	/**
	 * Set active list item
	 * @param activeListItem - string representation of active list item
	 */
	public setActiveListItem(activeListItem: string): void {
		this.activeListItem = activeListItem;
		this.activeListItem$.next(activeListItem);
	}

	/**
	 * Get active list item
	 * @returns activeListItem - string representation of active list item
	 */
	public getActiveListItem$(): Observable<string> {
		return this.activeListItem$;
	}

	/**
	 * Get active list item
	 * @returns activeListItem - string representation of active list item
	 */
	public getActiveListItem(): string {
		return this.activeListItem;
	}
	/**
	 * Set permission flag
	 * @param isPermissionAvailable - string representation of permission flag
	 */
	public setIsPermissionAvailable(isPermissionAvailable: boolean): void {
		this.isPermissionAvailable$.next(isPermissionAvailable);
	}

	/**
	 * Get permission flag
	 * @returns isPermissionAvailable - string representation of permission flag
	 */
	public getIsPermissionAvailable$(): Observable<boolean> {
		return this.isPermissionAvailable$;
	}

	/**
	 * Set request business account permission
	 * @param toggleSecondaryNav - string representation of toggle secondary header
	 */
	public setRequestBusinessPermission(isPermissionAvailable: boolean): void {
		this.requestBusinessPermission = isPermissionAvailable;
	}

	/**
	 * Get permission status for request for business account
	 * @returns requestBusinessPermission - boolean representation of toggle secondary header
	 */
	public getRequestBusinessPermission(): boolean {
		return this.requestBusinessPermission;
	}
}
